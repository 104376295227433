import React from "react";
import TopNav from "../components/TopNav";

function Term() {
  return (
    <div>
      <h1>Terms and Conditions</h1>
      <p>
        <strong>
          PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS
          SITE. THEY LAY OUT THE RULES FOR USING OUR SERVICES.
        </strong>
      </p>

      <h2>Who We Are and How to Contact Us</h2>
      <p>
        Site: Makkaan.com
        <br />
        We/Us/Our: Makkaan.com, a company registered in Pakistan with a
        registered office at Jackson Heights 2nd and 3rd Floor Main GT Road
        Rawalpindi.
        <br />
        Contact Us:
        <br />
        Email: <a href="mailto:info@makkaan.com">info@makkaan.com</a>
        <br />
        UAN: +92 331-11-11-049
      </p>

      <h2>How These Terms Apply to You</h2>
      <p>
        By using the Site, you confirm that you accept these Terms and agree to
        comply with them.
        <br />
        If you do not agree, please do not use the Site.
        <br />
        We recommend that you print a copy of these Terms for your records.
        <br />
        These Terms may change over time.
        <br />
        Each time you visit the Site, you agree to check for any updates.
      </p>

      <h2>Other Terms That May Apply to You</h2>
      <p>
        In addition to these Terms, the following additional terms and
        conditions (“Associated Terms”) will apply to your use of the Site:
      </p>
      <ul>
        <li>Our Cookie Policy</li>
        <li>Our Acceptable Use Policy</li>
        <li>Our Member Terms (if you are a registered Member)</li>
      </ul>

      <h2>The Site</h2>
      <ul>
        <li>We may update and change the Site as necessary.</li>
        <li>The Site is available to users free of charge.</li>
        <li>
          While we strive to ensure the Site remains available, we cannot
          guarantee uninterrupted access.
        </li>
        <li>We may suspend or restrict access at times.</li>
        <li>
          You are responsible for ensuring that anyone accessing the Site
          through your internet connection is aware of these Terms and complies
          with them.
        </li>
        <li>
          The Site is intended for international use, and you must be over the
          age of 18 to access it.
        </li>
        <li>
          You agree to keep any security credentials (like passwords and
          usernames) confidential and not share them with others.
        </li>
        <li>
          We reserve the right to disable your access if we suspect any breach
          of these Terms.
        </li>
      </ul>

      <h2>Using Material on the Site</h2>
      <p>
        We own or are licensed to use all intellectual property rights in the
        Site and its materials. These works are protected globally by copyright
        laws, and all rights are reserved.
        <br />
        You may print one copy and download extracts of any page(s) from the
        Site for personal use only.
        <br />
        Modification of any materials is prohibited.
        <br />
        For commercial use of materials, please obtain written permission from
        us.
      </p>

      <h2>Content Accuracy</h2>
      <p>
        The content on this Site is provided for general information purposes
        only and should not be considered professional advice. Always seek
        appropriate advice before taking action based on information on this
        Site.
        <br />
        While we aim to keep the information up-to-date, we make no guarantees
        regarding accuracy or timeliness.
      </p>

      <h2>Links to Other Websites</h2>
      <p>
        Links to third-party websites are provided for your convenience. We do
        not endorse or assume any responsibility for these sites.
      </p>

      <h2>User-Generated Content</h2>
      <p>
        The Site may include content uploaded by users. We do not verify or
        approve this content, and any opinions expressed do not necessarily
        reflect our views.
        <br />
        If you have concerns about user-generated content, please contact us at
        +92 331-11-11-049.
      </p>

      <h2>Uploading Content to the Site</h2>
      <p>
        When you upload content, you agree to comply with our Acceptable Use
        Policy. By uploading content, you grant us a limited license to use and
        distribute that content.
        <br />
        We have the right to remove any content that does not comply with our
        standards.
      </p>

      <h2>Our Liability</h2>
      <p>
        We do not exclude liability where it would be unlawful to do so,
        including for death or personal injury due to our negligence.
        <br />
        If you are a business user, we exclude all implied terms and conditions,
        and we are not liable for indirect or consequential losses.
        <br />
        If you are a consumer user, we only provide the Site for personal use,
        and we have no liability for business losses.
      </p>

      <h2>Security Responsibilities</h2>
      <p>
        We do not guarantee that the Site is free from viruses.
        <br />
        You are responsible for configuring your technology to access the Site
        safely.
        <br />
        You must not introduce harmful material or attempt unauthorized access
        to the Site.
      </p>

      <h2>Links to Our Site</h2>
      <p>
        You may link to our homepage, provided that it is done fairly and
        legally without damaging our reputation. We reserve the right to
        withdraw linking permission at any time.
      </p>

      <h2>Governing Law</h2>
      <p>
        These Terms and their formation are governed by the laws of Pakistan,
        and any disputes will be subject to the exclusive jurisdiction of the
        courts of Pakistan.
      </p>

      <h2>Definitions</h2>
      <p>In these Terms, the following definitions apply:</p>
      <ul>
        <li>You, Your: The person visiting the Site.</li>
        <li>User: Any person using the Site.</li>
        <li>Member: A User who registers on the Site.</li>
        <li>Content: All information provided on the Site.</li>
        <li>Terms: These terms and conditions and any Associated Terms.</li>
      </ul>

      <h2>Cookie Policy</h2>
      <p>
        Our website uses cookies to enhance your experience. By continuing to
        browse the Site, you consent to our use of cookies. For more details,
        please see our Cookie Policy.
      </p>
    </div>
  );
}

export default Term;
