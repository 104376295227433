import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Signup from "./pages/signup/Signup";
import Login from "./pages/Login/Login";
import Addlocation from "./pages/Addlocation";
import AgencyProfile from "./pages/Agency/AgencyProfile";
import PackagesView from "./pages/Packages/PackagesView";
import AgencyList from "./pages/Agency/AgencyList";
import ProjectsList from "./pages/Projects/ProjectsList";
import ProjectView from "./pages/Projects/ProjectView";
import DeveloperList from "./pages/Developers/DeveloperList";
import DeveloperDetails from "./pages/Developers/DeveloperDetails";
import ViewProperty from "./pages/ViewProperty/ViewProperty";
import AllProperties from "./components/property/AllProperties";
import Contact from "./pages/Contact/Contact";
import AboutUs from "./pages/About/AboutUs";
import Agencies from "./pages/Agencies/Agencies";
import Blogs from "./pages/Blogs/Blogs";
import Events from "./pages/Events/Events";
import Career from "./pages/Career/Career";
import OurTeam from "./pages/ourTeam/OurTeam";
import AllPropertiesCity from "./components/property/AllPropertiesCity";
import SellerWrapper from "./pages/SellerWrapper";
import UserSettings from "./pages/userSettings/UserSettings";
import Agencystaff from "./pages/Agencystaff";
import PropertyManagements from "./pages/Managemnets/PropertyManagemnets";
import Plan from "./pages/Plan/Plan";
import PostListing from "./pages/postListing/PostListing";
import ViewBlogs from "./pages/Blogs/ViewBlogs";
import CapitalCity from "./pages/CapitalCity/CapitalCity";

import News from "./components/news/News";
import ViewInvest from "./pages/ViewInvest";
import FavoriteProperty from "./pages/FavoriteProperty/FavoriteProperty";
import ContentBank from "./pages/ContentBank/ContentBank";
import { useEffect } from "react";
import ProjectViews from "./components/ProjectList/ProjectViews";
import Blueworldcity from "./makkaanpages/Blueworldcity";
import Blueworldtradecenter from "./makkaanpages/Blueworldtradecenter";
import Karatcrown from "./makkaanpages/Karatcrown";
import Akbarvista from "./makkaanpages/Akbarvista";
import Arclink from "./makkaanpages/Archlink";
import Pearlbusinesscenter from "./makkaanpages/Pearlbusinesscenter";
import Ninek from "./makkaanpages/Ninek";
import Nathiagali from "./makkaanpages/Nineknathia";
import Avenue18 from "./makkaanpages/Avenue18";
import Peaknest from "./makkaanpages/Peaknest";
import Policypage from "./policyandterms/Policypage";
import Term from "./policyandterms/Term";

function App() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/avenue-18" element={<Avenue18 />} />
          <Route path="/24-karat-crown" element={<Karatcrown />} />
          <Route path="/27-arclink" element={<Arclink />} />
          <Route path="/akbar-vista" element={<Akbarvista />} />
          <Route path="/9k-suite-ayubia" element={<Ninek />} />
          <Route path="/9k-suite-nathiagali" element={<Nathiagali />} />
          <Route path="/peak-nest" element={<Peaknest />} />
          <Route
            path="/pearl-business-center"
            element={<Pearlbusinesscenter />}
          />
          <Route
            path="/blue-world-city-islamabad/"
            element={<Blueworldcity />}
          />
          <Route
            path="/blue-world-trade-center"
            element={<Blueworldtradecenter />}
          />
          <Route path="/privacy-and-policy" element={<Policypage />} />
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/news" element={<News />} />
          <Route path="/addProject" element={<Addlocation />} />
          <Route path="/addProject/:id" element={<Addlocation />} />
          <Route path="/agenciesdetails/:id" element={<AgencyProfile />} />
          <Route path="/packages" element={<PackagesView />} />
          <Route path="/agency_list" element={<AgencyList />} />
          <Route path="/projects_list" element={<ProjectsList />} />
          <Route path="/term-and-condition" element={<Term />} />
          <Route
            path="projects_list/projectdetails/:id"
            element={<ProjectView />}
          />
          <Route path="/project_view/:projectId" element={<ProjectViews />} />
          <Route path="/developer_list" element={<DeveloperList />} />
          <Route path="/developerdetails/:id" element={<DeveloperDetails />} />
          <Route path="property/:title/:id" element={<ViewProperty />} />
          <Route path="/all_properties" element={<AllProperties />} />
          <Route path="/all_properties/:city" element={<AllPropertiesCity />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about_us" element={<AboutUs />} />
          {/* <Route path='/contact' element={<Agencies />} /> */}
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog/:name" element={<ViewBlogs />} />
          <Route path="/events" element={<Events />} />
          <Route path="/career" element={<Career />} />
          <Route path="/favorite_property" element={<FavoriteProperty />} />
          <Route path="/plan" element={<Plan />} />
          <Route path="/invest" element={<CapitalCity />} />
          <Route path="/bankContact" element={<ContentBank />} />
          <Route path="/our_team" element={<OurTeam />} />
          <Route path="/viewInvest" element={<ViewInvest />} />
          <Route element={<SellerWrapper />}>
            <Route path="/dashboard" element={<PostListing />} />
            <Route
              path="/property_managements"
              element={<PropertyManagements />}
            />
            <Route path="/post_listing" element={<PostListing />} />
            <Route path="/user_setting" element={<UserSettings />} />
            <Route path="/Agencystaff" element={<Agencystaff />} />
            <Route path="/privacy-policy" element={<Policypage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
