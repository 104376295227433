import React from "react";
import { useEffect, useState } from "react";

function Test() {
  const [Count, setCount] = useState(null);

  useEffect(() => {
    console.log("Component rendered successfully");
  }, []);

  const btnClick = () => {
    setCount((Count) => Count + 1);
    setCount((Count) => Count + 1);
  };
  return (
    <div>
      <button onClick={btnClick}>
        <p>you clicked {Count} times</p>
      </button>
    </div>
  );
}

export default Test;
