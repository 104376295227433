import React, { useEffect, useState, useRef } from "react";
// import ReactDOM from 'react-dom';
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
import TopNav from "../../components/TopNav";
import BottomNav from "../../components/BottomNav";
import Footer from "../../components/footer/Footer";

import Propertyslider from "../../components/Propertyslider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import api_url from "../../ApiUrl";
import Relatedproject from "../../components/ProjectList/Relatedproject";

import "./ViewProperty.css";

const ViewProperty = () => {
  const stickyRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const sticky = stickyRef.current;
      if (sticky) {
        const offset = window.scrollY;
        if (offset > sticky.offsetTop) {
          sticky.style.position = "fixed";
          sticky.style.top = "0";
        } else {
          sticky.style.position = "relative";
          sticky.style.top = "initial";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { id, title } = useParams();

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const [myPost, setMyPost] = useState([]);
  const [property, setProperty] = useState({});

  // useEffect(() => {
  //   axios
  //     .get(api_url + `property/get/${id}`)
  //     .then((res) => {
  //       console.log(res.data, "==========>img");
  //       setMyPost(res.data.data);
  //       setProperty(res.data.data); // Assuming res.data.data is the property object
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, [api_url, id]); // Ensure all dependencies are included

  // Convert area from square meters to marla and kanal
  const convertArea = (areaInSquareMeters) => {
    const marla = areaInSquareMeters / 25.2929;
    const kanal = Math.floor(marla / 20);
    const remainingMarla = Math.round(marla % 20);

    if (kanal > 0) {
      return `${kanal} Kanal${
        remainingMarla > 0 ? ` ${remainingMarla} Marla` : ""
      }`;
    } else {
      return `${remainingMarla} Marla`;
    }
  };

  // Convert price to thousand, lakh, and crore
  const formatPrice = (price) => {
    if (price >= 1_00_00_000) {
      return `${Math.floor(price / 1_00_00_000)} Crore`;
    } else if (price >= 1_00_000) {
      return `${Math.floor(price / 1_00_000)} Lakh`;
    } else if (price >= 1_000) {
      return `${Math.floor(price / 1_000)} Thousand`;
    } else {
      return `${price}`;
    }
  };

  useEffect(() => {
    axios
      .get(`${api_url}property/get/${id}`)
      .then((res) => {
        const data = res.data.data;

        // Check the API response
        // console.log(data, "API Response Data");

        // Transform the data
        const formattedData = {
          ...data,
          formattedPrice: data.price ? formatPrice(Number(data.price)) : "N/A",
          convertedArea: data.size ? convertArea(Number(data.size)) : "N/A",
        };

        // console.log(formattedData, "==========> Transformed Data");
        setMyPost(formattedData);
        setProperty(formattedData); // Assuming property object needs transformation
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [api_url, id]); // Ensure all dependencies are included

  // console.log(myPost, "view property data");

  const submitForm = () => {
    // Retrieve values from input fields
    const name = document.getElementById("name")?.value.trim();
    const phone = document.getElementById("phone")?.value.trim();
    // const email = document.getElementById("email")?.value.trim();
    const subject = document.getElementById("subject")?.value.trim();
    const message = document.getElementById("message")?.value.trim();

    // Basic email format validation
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!email || !emailRegex.test(email)) {
    //   toast.error("Must enter a valid email");
    //   return;
    // }

    // Validation checks
    if (!name) {
      toast.error("Must enter name");
    } else if (!phone) {
      toast.error("Must enter phone number");
    } else if (!subject) {
      toast.error("Must enter subject");
    } else if (!message) {
      toast.error("Must enter message");
    } else {
      // All validations passed, proceed with form submission
      const params = {
        name,
        // email,
        phone,
        subject,
        message,
        propertyId: id, // Ensure 'id' is defined in your scope
        propertymail: myPost?.users?.email || "makkaan14@gmail.com",
      };

      // Logging for debugging
      // console.log("Params:", params);

      // Send data via axios
      axios
        .post(api_url + `enquiry/create`, params)
        .then((res) => {
          // console.log("Response:", res.data);
          if (res.data.status === "ok") {
            toast.success("Enquiry created successfully");

            // Reload the page after 3 seconds
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            toast.error(res.data.message || "Error creating enquiry");
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error(
            error.response?.data?.message || "Error sending the form"
          );
        });
    }
  };

  const feature = myPost?.feature && myPost?.feature.split(",");

  // console.log(feature, "===========================>>>>>>>>>>>");

  const getYouTubeEmbedUrl = (url) => {
    try {
      const urlObj = new URL(url);
      let videoId = "";

      // Check if URL is a standard YouTube URL with ?v= parameter
      if (
        urlObj.hostname === "www.youtube.com" ||
        urlObj.hostname === "youtube.com"
      ) {
        videoId = urlObj.searchParams.get("v");
      }

      // Check if URL is a short youtu.be URL
      if (urlObj.hostname === "youtu.be") {
        videoId = urlObj.pathname.slice(1);
      }

      // Return the embed URL
      return videoId ? `https://www.youtube.com/embed/${videoId}` : "";
    } catch (error) {
      console.error("Invalid URL:", error);
      return "";
    }
  };
  const videoUrl = myPost?.video ? getYouTubeEmbedUrl(myPost.video) : "";

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  const myId = localStorage.getItem("mainUserId");
  const [myUser, setMyUser] = useState();

  useEffect(() => {
    axios.get(`${api_url}user/get/${myId}`).then((res) => {
      // console.log(
      //   res.data.data,
      //   "userdata======================>>>>>>>>>>>>>>"
      // );
      setMyUser(res.data.data);
    });
  }, []);

  return (
    <>
      {myPost && myPost ? (
        <div className="position-relative view_property">
          <ToastContainer />
          <div className=" position-fixed w-100" style={{ zIndex: "999999" }}>
            <TopNav />
            <BottomNav />
          </div>

          <div className="container-fluid" style={{ paddingTop: "8%" }}>
            <div className="row data">
              <div className="col-lg-9">
                <h3 className="text-dark">{myPost?.title}</h3>
                <p className="text-dark">{myPost?.location}</p>
                {/* {myPost?.advancePayment &&
                  myPost?.installments &&
                  myPost?.installments !== "" &&
                  myPost?.monthlyInstallments && (
                    <span
                      className="badge rounded-pill mb-3 py-2 px-2"
                      style={{ fontSize: "15px", backgroundColor: "#4db749" }}
                    >
                      On Installments
                    </span>
                  )} */}
                {myPost?.advancePayment &&
                  myPost?.installments &&
                  myPost?.installments !== null &&
                  myPost?.installments !== undefined &&
                  myPost?.installments !== "" &&
                  myPost?.installments !== 0 &&
                  myPost?.monthlyInstallments &&
                  myPost?.monthlyInstallments !== null &&
                  myPost?.monthlyInstallments !== undefined &&
                  myPost?.monthlyInstallments !== "" &&
                  myPost?.monthlyInstallments !== 0 && (
                    <span
                      className="badge rounded-pill mb-3 py-2 px-2"
                      style={{ fontSize: "15px", backgroundColor: "#4db749" }}
                    >
                      On Installments
                    </span>
                  )}
                <Propertyslider />

                <div className="row marlaplot">
                  <div className="d-flex flex-row mb-3">
                    {myPost?.convertedArea !== 0 && (
                      <div className="p-2">
                        <div className="d-flex align-items-start">
                          <img
                            src={require("./../../img/wide.png")}
                            alt=""
                            style={{ width: "25px", height: "25px" }}
                          />

                          <div className="ms-3 mt-1">
                            <p>Area {myPost?.convertedArea}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {myPost?.beds && (
                      <div className="p-2">
                        <div className="d-flex align-items-start">
                          <img
                            src={require("./../../img/beds.png")}
                            alt=""
                            style={{ width: "25px", height: "25px" }}
                          />
                          <div className="ms-3 mt-1">
                            <p>{myPost?.beds} Beds</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {myPost?.baths && (
                      <div className="p-2">
                        <div className="d-flex align-items-start">
                          <img
                            src={require("./../../img/baths.png")}
                            alt=""
                            style={{ width: "25px", height: "25px" }}
                          />

                          <div className="ms-3 mt-1">
                            <p>{myPost?.baths} Baths</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div
                      className="list-group list-group-light"
                      id="list-tab"
                      role="tablist"
                    >
                      <a
                        className="list-group-item list-group-item-action active px-3 border-0"
                        id="list-home-list"
                        data-mdb-list-init
                        href="#overview"
                        role="tab"
                        aria-controls="list-home"
                      >
                        Overview
                      </a>
                      <a
                        className="list-group-item list-group-item-action px-3 border-0"
                        id="list-profile-list"
                        data-mdb-list-init
                        href="#description"
                        role="tab"
                        aria-controls="list-profile"
                      >
                        Description
                      </a>
                      <a
                        className="list-group-item list-group-item-action px-3 border-0"
                        id="list-messages-list"
                        data-mdb-list-init
                        href="#amenities"
                        role="tab"
                        aria-controls="list-messages"
                      >
                        Amenities
                      </a>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 mt-5 ">
                    <div className="row  " id="overview">
                      <div className="col-12">
                        <h3 className="mb-2" style={{ color: "#4db749" }}>
                          Overview
                        </h3>
                        <h5 className="mb-2">Details</h5>

                        <div className="row">
                          <div
                            className="col-xl-4 col-lg-6 mb-4 mt-4"
                            style={{
                              borderBottom: "solid 1px #e5e7ea",
                              paddingBottom: "10px",
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <img
                                src={require("./../../img/type.png")}
                                alt=""
                                style={{ width: "25px", height: "25px" }}
                              />

                              <div className="ms-3">
                                <p className="   mb-1">Type {myPost?.type}</p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-xl-4 col-lg-6 mb-4 mt-4"
                            style={{
                              borderBottom: "solid 1px #e5e7ea",
                              paddingBottom: "10px",
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <img
                                src={require("./../../img/label.png")}
                                alt=""
                                style={{ width: "25px", height: "25px" }}
                              />
                              <div className="ms-3">
                                <p className=" mb-1">
                                  Price {myPost?.formattedPrice}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-xl-4 col-lg-6 mb-4 mt-4"
                            style={{
                              borderBottom: "solid 1px #e5e7ea",
                              paddingBottom: "2em",
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <img
                                src={require("./../../img/wide.png")}
                                alt=""
                                style={{ width: "30px", height: "30px" }}
                              />
                              <div className="ms-3">
                                <p className=" mb-1">
                                  Area {myPost?.convertedArea}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                            <div className="d-flex align-items-center">
                              <img
                                src={require("./../../img/targett.png")}
                                alt=""
                                style={{ width: "30px", height: "30px" }}
                              />
                              <div className="ms-3">
                                <p className=" mb-1">
                                  Purpose {myPost?.purpose}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                            <div className="d-flex align-items-center">
                              <img
                                src={require("./../../img/pin.png")}
                                alt=""
                                style={{ width: "30px", height: "30px" }}
                              />
                              <div className="ms-3">
                                <p className="  mb-1">{myPost?.location}</p>
                              </div>
                            </div>
                          </div>
                          {myPost?.beds && (
                            <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                              <div className="d-flex align-items-center">
                                <img
                                  src={require("./../../img/beds.png")}
                                  alt=""
                                  style={{ width: "30px", height: "30px" }}
                                />
                                <div className="ms-3">
                                  <p className="  mb-1">{myPost?.beds} Beds</p>
                                </div>
                              </div>
                            </div>
                          )}
                          {myPost?.baths && (
                            <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                              <div className="d-flex align-items-center">
                                <img
                                  src={require("./../../img/baths.png")}
                                  alt=""
                                  style={{ width: "25px", height: "25px" }}
                                />
                                <div className="ms-3">
                                  <p className=" mb-1">{myPost?.baths} Baths</p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col">
                        <h3
                          id="description"
                          className="mb-2"
                          style={{ color: "#4db749" }}
                        >
                          Descripition
                        </h3>
                        <p style={{ lineHeight: "1.8em" }}>
                          {myPost?.description}
                        </p>
                      </div>
                    </div>

                    <div className="row mt-5" id="amenities">
                      <div className="col-12">
                        <h3 className="mb-2" style={{ color: "#4db749" }}>
                          Amenities
                        </h3>
                        <ul
                          class="nav nav-pills mb-3"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link active"
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              type="button"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              Primary Features
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-profile"
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              Utlities
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="pills-contact-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-contact"
                              type="button"
                              role="tab"
                              aria-controls="pills-contact"
                              aria-selected="false"
                            >
                              Communication
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="pills-landmark-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-landmark"
                              type="button"
                              role="tab"
                              aria-controls="pills-contact"
                              aria-selected="false"
                            >
                              LandMark NearBy
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button
                              class="nav-link"
                              id="pills-sfeature-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-sfeature"
                              type="button"
                              role="tab"
                              aria-controls="pills-contact"
                              aria-selected="false"
                            >
                              Secondary Features
                            </button>
                          </li>
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                          <div
                            class="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                          >
                            <h5 className="mb-2">Primary Features</h5>
                            <div className="row">
                              {myPost?.buildYear !== 0 && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />

                                    <div className="ms-3">
                                      <p className="mb-1">
                                        Build Year {myPost?.buildYear}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {myPost?.tvLounge !== 0 && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className="  mb-1">
                                        {myPost?.tvLounge} TV Lounge
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {myPost?.storeRooms !== 0 && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        {myPost?.storeRooms} Store Rooms
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.laundryRoom !== 0 && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className="  mb-1">
                                        {myPost?.laundryRoom} laundry Room
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {myPost?.studyRoom !== 0 && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className="  mb-1">
                                        {myPost?.studyRoom} Study Room
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {myPost?.diningRoom !== 0 && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className="  mb-1">
                                        {myPost?.diningRoom} Dining Room
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.drawingRoom !== 0 && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        {myPost?.drawingRoom} Drawing Room
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.powderRoom !== 0 && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        {myPost?.powderRoom} Powder Room
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {myPost?.serventQuarter && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        {myPost?.servantQuarter} Servent Quarter
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.balcony !== 0 && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className="mb-1">
                                        {myPost?.balcony} Balcony
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.kitchen !== 0 && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className="  mb-1">
                                        {myPost?.kitchen} Kitchen
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {myPost?.furnished && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />{" "}
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Furnished : {myPost?.furnished}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {myPost?.semiFurnished && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Semi Furnished : {myPost?.semiFurnished}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {myPost?.cornerPlot && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Corner Plot : {myPost?.cornerPlot}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {myPost?.basement && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Basement : {myPost?.basement}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="pills-profile"
                            role="tabpanel"
                            aria-labelledby="pills-profile-tab"
                          >
                            <div className="row">
                              <h5 className="mb-2">Utlities</h5>
                              {myPost?.electricity && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Electricity : {myPost?.electricity}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.sewerage && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Sewerage : {myPost?.sewerage}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.watersupply && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Water Supply: {myPost?.watersupply}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.gas && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Gas: {myPost?.gas}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="pills-contact"
                            role="tabpanel"
                            aria-labelledby="pills-contact-tab"
                          >
                            <div className="row">
                              <h5 className="mb-2">Communication</h5>
                              {myPost?.security && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Security: {myPost?.security}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.internet && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Internet: {myPost?.internet}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="pills-landmark"
                            role="tabpanel"
                            aria-labelledby="pills-landmark-tab"
                          >
                            <div className="row">
                              <h5 className="mb-2">Landmarks Nearby</h5>

                              {myPost?.school && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        School: {myPost?.school}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.hospital && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Hospital: {myPost?.hospital}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.mosque && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Mosque: {myPost?.mosque}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.restaurants && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Restaurants: {myPost?.restaurants}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="pills-sfeature"
                            role="tabpanel"
                            aria-labelledby="pills-sfeature-tab"
                          >
                            <div className="row">
                              <h5 className="mb-2">Secondary Features</h5>

                              {myPost?.lawn && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        lawn: {myPost?.lawn}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.pool && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Swimming Pool: {myPost?.pool}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.lift && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Lift: {myPost?.lift}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.entry && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Sperate Entry: {myPost?.entry}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.hometheater && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Home Theater: {myPost?.hometheater}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.heating && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Central Heating: {myPost?.heating}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.cooling && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Central Cooling: {myPost?.cooling}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.accessibility && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Accessibility: {myPost?.accessibility}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.maintenance && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Maintenance: {myPost?.maintenance}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.floors !== 0 && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Floors: {myPost?.floors}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.floorunit && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Floor Unit: {myPost?.floorunit}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {myPost?.facing && (
                                <div className="col-xl-4 col-lg-6 mb-4 mt-4">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={require("./../../img/Tick.png")}
                                      alt=""
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                    <div className="ms-3">
                                      <p className=" mb-1">
                                        Facing: {myPost?.facing}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 ">
                <div className="card p-3">
                  <h4
                    style={{
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "24px",
                    }}
                  >
                    PKR {myPost?.formattedPrice}
                  </h4>

                  <h5
                    style={{
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "24px",
                    }}
                  >
                    Get in touch today!
                  </h5>
                  <span
                    className="text-muted"
                    style={{
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "15.6px",
                    }}
                  >
                    send a message and get in touch today.
                  </span>
                  <br />
                  <div className="d-flex align-items-center">
                    <div className="ms-3">
                      <button
                        type="button"
                        class="btn btn-dark btn-lg btn-block w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      >
                        <i
                          style={{ marginRight: "10px" }}
                          class="fas fa-phone text-dark blink"
                        ></i>
                        Call Now
                      </button>

                      <div
                        class="modal fade"
                        id="staticBackdrop"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog modal-dialog-centered">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="staticBackdropLabel">
                                Contact Details
                              </h5>
                              <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body">
                              <h5>Agent Name</h5>
                              <i
                                className="bi bi-person"
                                style={{ marginRight: "15px" }}
                              ></i>
                              {myUser?.fullName}
                              <h5>Phone Number</h5>

                              <a
                                href={`tel:${myPost?.phone}`}
                                className="text-decoration-none"
                              >
                                <i
                                  className="bi bi-phone"
                                  style={{ marginRight: "15px" }}
                                ></i>
                                {myPost?.phone}{" "}
                              </a>
                            </div>
                            <div class="modal-footer">
                              <button
                                onClick={() =>
                                  navigator.clipboard.writeText(myPost?.phone)
                                }
                                type="button"
                                class="btn btn-light"
                                data-bs-dismiss="modal"
                              >
                                <i
                                  style={{ marginRight: "15px" }}
                                  className="fas fa-copy"
                                ></i>{" "}
                                Copy Number
                              </button>
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-d-12 mt-3">
                      <div data-mdb-input-init className="form-outline">
                        <input
                          type="text"
                          id="name"
                          className="form-control border-bottom"
                        />
                        <label
                          className="form-label"
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "16.8px",
                          }}
                          for="htmlFor"
                        >
                          Your Name
                        </label>
                      </div>
                    </div>
                    <div className="col-d-12 mt-3">
                      <div data-mdb-input-init className="form-outline">
                        <input
                          type="number"
                          id="phone"
                          className="form-control border-bottom"
                        />
                        <label
                          className="form-label"
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "16.8px",
                          }}
                          for="htmlFor"
                        >
                          Phone Number
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-d-12 mt-3">
                      {/* <div data-mdb-input-init className="form-outline">
                        <input
                          type="email"
                          id="email"
                          className="form-control border-bottom"
                        />
                        <label
                          className="form-label"
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "16.8px",
                          }}
                          for="htmlFor"
                        >
                          Email Address
                        </label>
                      </div> */}
                    </div>
                    <div className="col-d-12 mt-3">
                      <div data-mdb-input-init className="form-outline">
                        <input
                          type="text"
                          id="subject"
                          className="form-control border-bottom"
                        />
                        <label
                          className="form-label"
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "16.8px",
                          }}
                          for="htmlFor"
                        >
                          Subject
                        </label>
                      </div>
                    </div>
                    <div className="col-d-12 mt-3">
                      <div data-mdb-input-init className="form-outline">
                        <input
                          type="text"
                          id="message"
                          className="form-control border-bottom"
                        />
                        <label
                          className="form-label"
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "16.8px",
                          }}
                          for="htmlFor"
                        >
                          Your Message
                        </label>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn bg_makkan text-white w-100 mt-3"
                    style={{ backgroundColor: "#4DB749" }}
                    onClick={submitForm}
                  >
                    SEND YOUR QUESTION
                  </button>
                  <p
                    style={{
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "15.6px",
                      marginTop: "20px",
                    }}
                  >
                    By submited this form, agree to Terms of Use.
                  </p>
                </div>
                {myPost?.agencyName && myPost?.agencyLogo && (
                  <Link to={`/developerdetails/${myPost?.userId}`}>
                    <div className="card text-center">
                      <div
                        className="card-header"
                        style={{ backgroundColor: "#4db749", color: "white" }}
                      >
                        {(myPost?.signAs || "").toUpperCase()} PROFILE
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">
                          {capitalizeFirstLetter(myPost?.agencyName || "")}
                        </h5>
                        <div className="card-text">
                          <img
                            src={myPost?.agencyLogo}
                            style={{
                              width: "30%",
                              height: "30%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </div>
                      <div className="card-footer text-muted">
                        <i
                          className="fas fa-user"
                          style={{ marginRight: "8px" }}
                        ></i>
                        {capitalizeFirstLetter(myPost?.fullName || "")}
                      </div>
                    </div>
                  </Link>
                )}
                {myPost?.advancePayment &&
                  myPost?.advancePayment !== null &&
                  myPost?.advancePayment !== undefined &&
                  myPost?.advancePayment !== "" &&
                  myPost?.advancePayment !== 0 &&
                  myPost?.installments &&
                  myPost?.installments !== null &&
                  myPost?.installments !== undefined &&
                  myPost?.installments !== "" &&
                  myPost?.installments !== 0 &&
                  myPost?.monthlyInstallments &&
                  myPost?.monthlyInstallments !== null &&
                  myPost?.monthlyInstallments !== undefined &&
                  myPost?.monthlyInstallments !== "" &&
                  myPost?.monthlyInstallments !== 0 && (
                    <div
                      className="card text-center"
                      style={{ marginTop: "2rem" }}
                    >
                      <div
                        className="card-header"
                        style={{ backgroundColor: "#4db749", color: "white" }}
                      >
                        INSTALLMENTS DETAILS
                      </div>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item py-3">
                          <b>Advance Payment:</b>&nbsp;{myPost?.advancePayment}
                        </li>
                        <li className="list-group-item py-3">
                          <b>No of Installments:</b>&nbsp;{myPost?.installments}
                        </li>
                        <li className="list-group-item py-3">
                          <b>Monthly Installments:</b>&nbsp;
                          {myPost?.monthlyInstallments}
                        </li>
                      </ul>
                      <div className="card-footer text-muted">
                        <p>Call Now For More Details</p>
                      </div>
                    </div>
                  )}

                {/* {myPost?.advancePayment &&
                  myPost?.installments &&
                  myPost?.monthlyInstallments && (
                    <div class="card text-center" style={{ marginTop: "2rem" }}>
                      <div
                        class="card-header"
                        style={{ backgroundColor: "#4db749", color: "white" }}
                      >
                        INSTALLMENTS DETAILS
                      </div>
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item py-3">
                          <b>Advance Payment:</b>&nbsp;{myPost?.advancePayment}
                        </li>
                        <li class="list-group-item py-3">
                          <b>No of Installments :</b> &nbsp;
                          {myPost?.installments}
                        </li>
                        <li class="list-group-item py-3">
                          <b> Monthly Installments:</b>&nbsp;
                          {myPost?.monthlyInstallments}
                        </li>
                      </ul>
                      <div className="card-footer text-muted">
                        <p>Call Now For More Details</p>
                      </div>
                    </div>
                  )} */}
              </div>
            </div>
          </div>

          <Relatedproject userId={property.userId} />

          <Footer />
        </div>
      ) : (
        <h2>No Property Found</h2>
      )}
    </>
  );
};

export default ViewProperty;
