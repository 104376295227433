import axios from "axios";
import React, { useEffect, useState } from "react";
import api_url from "../../ApiUrl";
import { Link, useNavigate } from "react-router-dom";
import { BiEdit } from "react-icons/bi";

const PropertyManagements = () => {
  const [myPost, setMyPost] = useState([]);
  const [queryid, setQueryid] = useState("");
  const [querycity, setQuerycity] = useState("");
  const [queryloc, setQueryloc] = useState("");
  const [querytype, setQuerytype] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(api_url + `property/get`).then((res) => {
      console.log(res.data, "Property Data");
      setMyPost(res.data.data);
    });
  }, []);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this property?")) {
      axios
        .delete(`${api_url}property/delete/${id}`)
        .then((res) => {
          console.log(res.data);
          setMyPost(myPost.filter((item) => item.id !== id));
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const formatTitle = (title) => {
    const formattedTitle = title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "");

    return formattedTitle.startsWith("-")
      ? formattedTitle.substring(1)
      : formattedTitle;
  };

  // const filterProperties = (status) => {
  //   return myPost
  //     .filter((i) => {
  //       const cityMatch =
  //         querycity.length === 0 ||
  //         (i.city &&
  //           i?.city.toLowerCase().includes(querycity.toLocaleLowerCase()));
  //       const idMatch = queryid.length === 0 || i.id.toString() === queryid;
  //       return (
  //         cityMatch &&
  //         idMatch &&
  //         i?.userId?.toString() === localStorage.getItem("mainUserId")
  //       );
  //     })
  //     .filter((i) => status === "All" || i?.Status === status);
  // };
  const filterProperties = (status) => {
    return myPost
      .filter((i) => {
        const cityMatch =
          querycity.length === 0 ||
          (i.city &&
            i?.city.toLowerCase().includes(querycity.toLocaleLowerCase()));

        const idMatch = queryid.length === 0 || i.id.toString() === queryid;

        const locationMatch =
          queryloc.length === 0 ||
          (i.location &&
            i?.location.toLowerCase().includes(queryloc.toLocaleLowerCase()));

        const typeMatch =
          querytype.length === 0 ||
          (i.type &&
            i?.type.toLowerCase().includes(querytype.toLocaleLowerCase()));

        return (
          cityMatch &&
          idMatch &&
          locationMatch &&
          typeMatch &&
          i?.userId?.toString() === localStorage.getItem("mainUserId")
        );
      })
      .filter((i) => status === "All" || i?.Status === status);
  };

  return (
    <>
      <section className="property_management m-4">
        <div className="container">
          <div className="row">
            {/* <div className="col-md-4">
              <div className="mb-3 row">
                <label
                  htmlFor="exampleInputEmail1"
                  className="col-sm-2 col-form-label"
                >
                  ID
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="ID"
                    onChange={(e) => setQueryid(e.target.value)}
                  />
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-4">
              <div className="mb-3 row">
                <label
                  htmlFor="exampleInputEmail1"
                  className="col-sm-2 col-form-label"
                >
                  City
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="City"
                    onChange={(e) => setQuerycity(e.target.value)}
                  />
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-4 row">
              <div className="col-md-12">
                <Link
                  to="/addProject"
                  className="btn btn-lg btn-outline"
                  style={{ backgroundColor: "#4DB749" }}
                >
                  Add property
                </Link>

                <Link
                  to="/packages"
                  className="btn btn-lg text-white"
                  style={{ backgroundColor: "#4DB749", marginLeft: "5px" }}
                >
                  Choose Plan
                </Link>
              </div>
            </div> */}
            {/* <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Location
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  placeholder="Location"
                  onChange={(e) => setQueryloc(e.target.value)}
                />
              </div>
            </div> */}
            {/* <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Property Type
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  placeholder="property Type"
                  onChange={(e) => setQuerytype(e.target.value)}
                />
              </div>
            </div> */}
          </div>

          <div className="row">
            <div className="col-md-12">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    All Listing
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-pending-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-pending"
                    type="button"
                    role="tab"
                    aria-controls="pills-pending"
                    aria-selected="false"
                  >
                    Pending
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-active-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-active"
                    type="button"
                    role="tab"
                    aria-controls="pills-active"
                    aria-selected="false"
                  >
                    Active
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-inactive-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-inactive"
                    type="button"
                    role="tab"
                    aria-controls="pills-inactive"
                    aria-selected="false"
                  >
                    Inactive
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <section className="bg-white">
                    <div className="container">
                      <div className="text-center py-2">
                        <table className="table">
                          <thead className="table-light">
                            <tr>
                              <th>S.No.</th>
                              <th>Title</th>
                              <th>City</th>
                              <th>Price</th>
                              <th>Location</th>
                              <th>Type</th>
                              <th>Purpose</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterProperties("All").map((i, index) => (
                              <tr key={i?.id}>
                                <td>{index + 1}</td>
                                <td>{i?.title}</td>
                                <td>{i?.city}</td>
                                <td>{i?.price}</td>
                                <td>{i?.location}</td>
                                <td>{i?.type}</td>
                                <td>{i?.purpose}</td>
                                <td>{i?.Status}</td>
                                <td>
                                  <i
                                    className="fa fa-eye"
                                    onClick={() =>
                                      navigate(
                                        `/property/${formatTitle(i?.title)}/${
                                          i?.id
                                        }`
                                      )
                                    }
                                  ></i>
                                  <i
                                    className="fa fa-trash"
                                    onClick={() => handleDelete(i.id)}
                                  ></i>
                                  <i
                                    className="fa fa-edit"
                                    onClick={() =>
                                      navigate(`/addProject/${i.id}`)
                                    }
                                  ></i>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-pending"
                  role="tabpanel"
                  aria-labelledby="pills-pending-tab"
                >
                  <section className="bg-white">
                    <div className="container">
                      <div className="text-center py-2">
                        <table className="table">
                          <thead className="table-light">
                            <tr>
                              <th>S.No.</th>
                              <th>Title</th>
                              <th>City</th>
                              <th>Price</th>
                              <th>Location</th>
                              <th>Type</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterProperties("pending").map((i, index) => (
                              <tr key={i?.id}>
                                <td>{index + 1}</td>
                                <td>{i?.title}</td>
                                <td>{i?.city}</td>
                                <td>{i?.price}</td>
                                <td>{i?.location}</td>
                                <td>{i?.type}</td>
                                <td>{i?.Status}</td>
                                <td>
                                  <i
                                    className="fa fa-eye"
                                    onClick={() =>
                                      navigate(
                                        `/property/${formatTitle(i?.title)}/${
                                          i?.id
                                        }`
                                      )
                                    }
                                  ></i>
                                  <i
                                    className="fa fa-trash"
                                    onClick={() => handleDelete(i.id)}
                                  ></i>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-active"
                  role="tabpanel"
                  aria-labelledby="pills-active-tab"
                >
                  <section className="bg-white">
                    <div className="container">
                      <div className="text-center py-2">
                        <table className="table">
                          <thead className="table-light">
                            <tr>
                              <th>S.No.</th>
                              <th>Title</th>
                              <th>City</th>
                              <th>Price</th>
                              <th>Location</th>
                              <th>Type</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterProperties("active").map((i, index) => (
                              <tr key={i?.id}>
                                <td>{index + 1}</td>
                                <td>{i?.title}</td>
                                <td>{i?.city}</td>
                                <td>{i?.price}</td>
                                <td>{i?.location}</td>
                                <td>{i?.type}</td>
                                <td>{i?.Status}</td>
                                <td>
                                  <i
                                    className="fa fa-eye"
                                    onClick={() =>
                                      navigate(
                                        `/property/${formatTitle(i?.title)}/${
                                          i?.id
                                        }`
                                      )
                                    }
                                  ></i>
                                  <i
                                    className="fa fa-trash"
                                    onClick={() => handleDelete(i.id)}
                                  ></i>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-inactive"
                  role="tabpanel"
                  aria-labelledby="pills-inactive-tab"
                >
                  <section className="bg-white">
                    <div className="container">
                      <div className="text-center py-2">
                        <table className="table">
                          <thead className="table-light">
                            <tr>
                              <th>S.No.</th>
                              <th>Title</th>
                              <th>City</th>
                              <th>Price</th>
                              <th>Location</th>
                              <th>Type</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterProperties("inactive").map((i, index) => (
                              <tr key={i?.id}>
                                <td>{index + 1}</td>
                                <td>{i?.title}</td>
                                <td>{i?.city}</td>
                                <td>{i?.price}</td>
                                <td>{i?.location}</td>
                                <td>{i?.type}</td>
                                <td>{i?.Status}</td>
                                <td>
                                  <i
                                    className="fa fa-eye"
                                    onClick={() =>
                                      navigate(
                                        `/property/${formatTitle(i?.title)}/${
                                          i?.id
                                        }`
                                      )
                                    }
                                  ></i>
                                  <i
                                    className="fa fa-trash"
                                    onClick={() => handleDelete(i.id)}
                                  ></i>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PropertyManagements;
