import React, { useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Navigation } from "swiper";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import api_url from "../../ApiUrl";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import Video from "./Video";
import "./homeslider.css";

const priceOptions = [
  0,
  5000,
  10000,
  20000,
  30000,
  40000,
  50000,
  75000,
  100000,
  150000,
  200000,
  300000,
  400000,
  500000,
  750000,
  1000000,
  1500000,
  2000000,
  3000000,
  4000000,
  5000000,
  10000000,
  20000000,
  30000000,
  40000000,
  50000000,
  null,
];

const areaOptions = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  12,
  15,
  20,
  50,
  100,
  200,
  500,
  "Any",
];

const unitOptions = [
  { label: "Marla", value: "marla" },
  { label: "Kanal", value: "kanal" },
];

const HomeSlider = () => {
  const navigate = useNavigate();

  const [myPost, setMyPost] = useState([]);

  const [minPrice, setMinPrice] = useState(
    localStorage.getItem("searchminprice") || ""
  );
  const [maxPrice, setMaxPrice] = useState(
    localStorage.getItem("searchmaxprice") || ""
  );

  const [minArea, setMinArea] = useState(
    localStorage.getItem("searchminarea") || ""
  );
  const [maxArea, setMaxArea] = useState(
    localStorage.getItem("searchmaxarea") || ""
  );
  const [areaUnit, setAreaUnit] = useState(
    localStorage.getItem("searchareaunit") || "Marla"
  );

  useEffect(() => {
    localStorage.setItem("searchminarea", minArea);
  }, [minArea]);

  useEffect(() => {
    localStorage.setItem("searchmaxarea", maxArea);
  }, [maxArea]);

  useEffect(() => {
    localStorage.setItem("searchminprice", minPrice);
  }, [minPrice]);

  useEffect(() => {
    localStorage.setItem("searchmaxprice", maxPrice);
  }, [maxPrice]);

  const [selectedOption, setSelectedOption] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    // Set initial value from localStorage
    const storedOption = localStorage.getItem("mysetprop") || "";
    setSelectedOption(storedOption);
  }, []);

  const handleClick = (option) => {
    localStorage.setItem("mysetprop", option);
    setSelectedOption(option);
  };

  useEffect(() => {
    // Set initial value from localStorage
    const storedOption = localStorage.getItem("mysetprop") || "";
    setSelectedOption(storedOption);
  }, []);

  // Fetch search suggestions
  const fetchSuggestions = (input) => {
    if (!input) {
      setSuggestions([]); // Clear suggestions if input is empty
      return;
    }

    axios
      .post(`${api_url}property/filter`, { location: input })
      .then((res) => {
        console.log("location data for suggestions:", res.data);
        // Assuming each item in res.data.data has a 'title' or 'city' field that you want to display
        setSuggestions(res.data.data.map((item) => item.location)); // Only use a property like 'title' or 'city'
      })
      .catch((error) => {
        console.error("Error fetching suggestions: ", error);
      });
  };

  // Handle input change and fetch suggestions
  const handleSearchInputChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);
    fetchSuggestions(input);
  };

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    setSearchInput(suggestion);
    setSuggestions([]);
  };

  const submitFilter = () => {
    const city = document.getElementById("city");
    const type = document.getElementById("type");
    // const title = document.getElementById("title");
    const location = document.getElementById("location");
    const beds = document.getElementById("beds");
    const conversionFactors = {
      Marla: 25.2929,
      Kanal: 505.857,
      // Add more units if needed
    };

    // Convert area values to square meters
    const minAreaInSqMeters =
      minArea && areaUnit && !isNaN(parseFloat(minArea))
        ? parseFloat(minArea) * (conversionFactors[areaUnit] || 1)
        : null;

    const maxAreaInSqMeters =
      maxArea && areaUnit && !isNaN(parseFloat(maxArea))
        ? parseFloat(maxArea) * (conversionFactors[areaUnit] || 1)
        : null;

    // Debugging: Log the inputs and results
    console.log("Original minArea:", minArea);
    console.log("Original maxArea:", maxArea);
    console.log("Selected areaUnit:", areaUnit);
    console.log("Converted minAreaInSqMeters:", minAreaInSqMeters);
    console.log("Converted maxAreaInSqMeters:", maxAreaInSqMeters);
    // Create the param object
    const filteredParams = {
      city: city?.value || null,
      type: type?.value || null,
      location: location?.value || null,
      beds: beds?.value || null,
      minPrice: minPrice || null,
      maxPrice: maxPrice || null,
      minArea: minAreaInSqMeters,
      maxArea: maxAreaInSqMeters,
      purpose: localStorage.getItem("mysetprop"),
    };

    console.log(filteredParams, "Search Fields data");

    // Remove null or undefined values from the object
    const sanitizedParams = Object.fromEntries(
      Object.entries(filteredParams).filter(
        ([_, value]) => value !== null && value !== undefined
      )
    );

    // Construct the URL with sanitized params
    const queryParams = new URLSearchParams(sanitizedParams).toString();
    const allPropertiesUrl = `/all_properties?${queryParams}`;

    // Redirect to the all properties page
    window.location.href = allPropertiesUrl;

    console.log(allPropertiesUrl, "Generated URL");
  };

  const [pricePop, setPricePop] = useState(false);
  const [bedPop, setBedPop] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded((prev) => !prev);
  };
  const [selectedOptionType, setSelectedOptionType] = useState(""); // Default option

  return (
    <>
      <Swiper
        spaceBetween={60}
        navigation
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper1"
      >
        <SwiperSlide>
          {/* Video Container */}
          <div>
            <Video />
          </div>

          <div className="home_slider pt-5">
            <div
              id="home_slider_content"
              className="container justify-content-center align-items-center d-flex-1"
              style={{ height: "100vh", width: "100%" }}
            >
              <div
                className="container justify-content-center align-items-center d-flex mobile"
                style={{ height: "100vh", width: "70%" }}
              >
                <div className="row w-100">
                  <div className="col-md-12">
                    <div className="text-center text-white">
                      <div
                        className="d-flex pt-3 justify-content-center flex-wrap flex-lg-nowrap"
                        style={{ gap: "5px" }}
                      >
                        {/* Buy Button */}
                        <div className="slider_num">
                          <button
                            className="btn text-white px-5 py-2"
                            id="mybuy"
                            style={{
                              borderRadius: 0,
                              backgroundColor:
                                selectedOption === "For Sale"
                                  ? "#3d3d3d"
                                  : "#4db749",
                            }}
                            onClick={() => handleClick("For Sale")}
                          >
                            BUY
                          </button>
                        </div>

                        {/* Rent Button */}
                        <div className="slider_num">
                          <button
                            className="btn text-white px-5 py-2"
                            id="myrent"
                            style={{
                              borderRadius: 0,
                              backgroundColor:
                                selectedOption === "For Rent"
                                  ? "#3d3d3d"
                                  : "#4db749",
                            }}
                            onClick={() => handleClick("For Rent")}
                          >
                            RENT
                          </button>
                        </div>

                        {/* Invest Button */}
                        <div className="slider_num invest-button">
                          <button
                            style={{
                              borderRadius: 0,
                              backgroundColor: "#4DB749",
                            }}
                            className="btn text-white px-5 py-2"
                            type="button"
                            id="invest"
                            onClick={() => navigate("/projects_list")}
                          >
                            PROJECTS
                          </button>
                        </div>
                      </div>
                      <div>
                        <div
                          className="py-2 slider_home2  mt-3"
                          style={{ backgroundColor: "#8B8B88" }}
                        >
                          <div className="row px-2">
                            <div className="col-md-3">
                              <select
                                className="form-select form-select-md py-2 "
                                name="city"
                                id="city"
                                required
                                defaultValue={localStorage.getItem(
                                  "searchcity"
                                )}
                                style={{ fontSize: "12px" }}
                              >
                                <option value="">Select City</option>
                                <option value="Islamabad">Islamabad</option>

                                <option value="Rawalpindi">Rawalpindi</option>
                              </select>
                            </div>
                            <div className="col-md-6">
                              <input
                                style={{
                                  fontSize: "16px",
                                  borderRadius: 0,
                                  lineHeight: 2,
                                }}
                                className="form-control py-2"
                                type="search"
                                placeholder="Location"
                                id="location"
                                value={searchInput}
                                onChange={handleSearchInputChange} // Fetch suggestions on input change
                              />
                              {suggestions.length > 0 && (
                                <ul className="suggestion-list">
                                  {suggestions.map((suggestion, index) => (
                                    <li
                                      style={{ fontSize: "12px" }}
                                      key={index}
                                      onClick={() =>
                                        handleSuggestionClick(suggestion)
                                      }
                                      className="suggestion-item"
                                    >
                                      {suggestion}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>

                            <div className="col-md-3">
                              <button
                                className="btn w-100 text-white"
                                style={{
                                  backgroundColor: "#4DB749",
                                  opacity: "0.8",

                                  borderRadius: 0,
                                }}
                                onClick={submitFilter}
                              >
                                Find
                              </button>
                            </div>
                          </div>
                          <div className="row px-2 ">
                            <p
                              className="text-white"
                              onClick={toggleExpansion}
                              style={{
                                cursor: "pointer",
                                textAlign: "left",
                                marginTop: "5px",
                                marginBottom: "0px",
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                fontSize: "12px",
                              }}
                            >
                              {isExpanded ? <FiChevronUp /> : <FiChevronDown />}{" "}
                              {isExpanded ? "Less Options" : "More Options"}
                            </p>
                          </div>
                          {/* Div to Show/Hide */}
                          {isExpanded && (
                            <div className=" row px-2 pt-1">
                              <div className="col-md-3">
                                <div>
                                  <select
                                    class="form-select form-select-md "
                                    aria-label=".form-select-md example"
                                    placeholder="Property Type"
                                    style={{ fontSize: "12px" }}
                                    id="type"
                                    name="type"
                                    defaultValue={localStorage.getItem(
                                      "searchtype"
                                    )}
                                    value={selectedOptionType}
                                    onChange={(e) =>
                                      setSelectedOptionType(e.target.value)
                                    }
                                  >
                                    <option selected>Any Type</option>
                                    <option value="house">House</option>
                                    <option value="farmHouse">
                                      Farm House
                                    </option>
                                    <option value="pentHouse">
                                      Pent House
                                    </option>
                                    <option value="upperPortion">
                                      Upper Portion
                                    </option>
                                    <option value="lowerPortion">
                                      Lower Portion
                                    </option>
                                    <option value="flat">Flat</option>
                                    <option value="office">Office</option>
                                    <option value="shop">Shop</option>

                                    <option value="plot">Plot</option>

                                    <option value="residentialPlot">
                                      Residential Plot
                                    </option>
                                    <option value="commercialPlot">
                                      Commercial Plot
                                    </option>
                                    <option value="agriculturalLand">
                                      Agricultural Land
                                    </option>
                                    <option value="industrialLand">
                                      Industrial Land
                                    </option>

                                    <option value="warehouse">WareHouse</option>
                                    <option value="factory">Factory</option>
                                    <option value="building">Building</option>
                                  </select>
                                </div>
                              </div>

                              <div
                                className={`${
                                  selectedOptionType === "plot" ||
                                  selectedOptionType === "shop" ||
                                  selectedOptionType === "residentialPlot" ||
                                  selectedOptionType === "commercialPlot" ||
                                  selectedOptionType === "office" ||
                                  selectedOptionType === "agriculturalLand"
                                    ? "col-md-4"
                                    : "col-md-3"
                                } position-relative`}
                              >
                                <div
                                  className="bg-white "
                                  onClick={() => setPricePop(!pricePop)}
                                >
                                  <input
                                    className="form-control p-0 bg-white border-0 px-3 "
                                    type="text"
                                    placeholder="Search Price"
                                    disabled
                                    style={{
                                      fontSize: "12px",
                                      lineHeight: 2.3,
                                    }}
                                  />
                                  <div className="d-flex justify-content-between align-items-center m-0 p-0 px-3 ">
                                    <p
                                      className="p-0 m-0 pb-1 text-dark"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {minPrice}
                                    </p>
                                    <p
                                      className="p-0 m-0 pb-1 text-dark"
                                      style={{ fontSize: "12px" }}
                                    >
                                      to
                                    </p>
                                    <p
                                      className="p-0 m-0 pb-1 text-dark"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {maxPrice}
                                    </p>
                                    <p
                                      className="p-0 m-0 pb-1 text-dark"
                                      style={{ fontSize: "12px" }}
                                    >
                                      ▼
                                    </p>
                                  </div>
                                </div>

                                {pricePop ? (
                                  <div className=" bg-white p-2 d-flex flex-column my-2 price-popup">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <div>
                                        <p
                                          className="text-dark"
                                          style={{ fontSize: "12px" }}
                                        >
                                          MIN
                                        </p>
                                        <select
                                          className="form-control"
                                          style={{ fontSize: "12px" }}
                                          name="minPrice"
                                          value={minPrice}
                                          onChange={(e) => {
                                            setMinPrice(e.target.value);
                                            // Hide the popup when a value is selected
                                          }}
                                        >
                                          {priceOptions.map((price, index) => (
                                            <option key={index} value={price}>
                                              {price}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                      <div>
                                        <p
                                          className="text-dark"
                                          style={{ fontSize: "12px" }}
                                        >
                                          MAX
                                        </p>
                                        <select
                                          className="form-control"
                                          name="maxPrice"
                                          style={{ fontSize: "12px" }}
                                          value={maxPrice}
                                          onChange={(e) => {
                                            setMaxPrice(e.target.value);
                                            setPricePop(false); // Hide the popup when a value is selected
                                          }}
                                        >
                                          {priceOptions.map((price, index) => (
                                            <option key={index} value={price}>
                                              {price}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              <div
                                className={`${
                                  selectedOptionType === "plot" ||
                                  selectedOptionType === "shop" ||
                                  selectedOptionType === "residentialPlot" ||
                                  selectedOptionType === "commercialPlot" ||
                                  selectedOptionType === "office" ||
                                  selectedOptionType === "agriculturalLand"
                                    ? "col-md-5"
                                    : "col-md-3"
                                } position-relative`}
                              >
                                <div
                                  className="bg-white "
                                  onClick={() => setBedPop(!bedPop)}
                                >
                                  <input
                                    className="form-control p-0 bg-white border-0 px-3 "
                                    type="text"
                                    style={{
                                      fontSize: "12px",
                                      lineHeight: 2.3,
                                    }}
                                    placeholder="Search Area"
                                    disabled
                                  />
                                  <div className="d-flex justify-content-between align-items-center m-0 p-0 px-3">
                                    <p
                                      className="p-0 m-0 pb-1 text-dark"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {minArea}
                                    </p>
                                    <p
                                      className="p-0 m-0 pb-1 text-dark"
                                      style={{ fontSize: "12px" }}
                                    >
                                      to
                                    </p>
                                    <p
                                      className="p-0 m-0 pb-1 text-dark"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {maxArea}
                                    </p>
                                    <p
                                      className="p-0 m-0 pb-1 text-dark"
                                      style={{ fontSize: "12px" }}
                                    >
                                      ▼
                                    </p>
                                  </div>
                                </div>

                                {bedPop ? (
                                  <div className="bg-white p-2 d-flex flex-column my-2 price-popup">
                                    <div className="select-container">
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "10px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div>
                                          <p
                                            className="text-dark"
                                            style={{ fontSize: "12px" }}
                                          >
                                            MIN
                                          </p>
                                          <input
                                            type="number"
                                            style={{
                                              fontSize: "16px",
                                              borderRadius: 0,
                                            }}
                                            className="form-control "
                                            name="minArea"
                                            value={minArea}
                                            onChange={(e) => {
                                              setMinArea(e.target.value);
                                              console.log(
                                                "Updated minArea:",
                                                e.target.value
                                              );
                                            }}
                                            placeholder="Enter minimum area"
                                          />
                                        </div>
                                        <div>
                                          <p
                                            className="text-dark"
                                            style={{ fontSize: "12px" }}
                                          >
                                            MAX
                                          </p>
                                          <input
                                            type="number"
                                            className="form-control rounded-select"
                                            name="maxArea"
                                            style={{
                                              fontSize: "16px",
                                              borderRadius: 0,
                                            }}
                                            value={maxArea}
                                            onChange={(e) => {
                                              setMaxArea(e.target.value);
                                              console.log(
                                                "Updated maxArea:",
                                                e.target.value
                                              );
                                              setBedPop(false);
                                            }}
                                            placeholder="Enter maximum area"
                                          />
                                        </div>
                                      </div>

                                      <div>
                                        <p
                                          className="text-dark"
                                          style={{ fontSize: "12px" }}
                                        >
                                          UNIT
                                        </p>
                                        <select
                                          className="form-control"
                                          name="areaUnit"
                                          style={{ fontSize: "12px" }}
                                          value={areaUnit}
                                          onChange={(e) => {
                                            setAreaUnit(e.target.value);
                                            console.log(
                                              "Updated areaUnit:",
                                              e.target.value
                                            );
                                            setBedPop(false);
                                          }}
                                        >
                                          {unitOptions.map((unit, index) => (
                                            <option
                                              key={index}
                                              value={unit.value}
                                            >
                                              {unit.label}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              {/* Beds Field - Hidden if "Plot" is selected */}
                              {selectedOptionType !== "plot" &&
                                selectedOptionType !== "shop" &&
                                selectedOptionType !== "residentialPlot" &&
                                selectedOptionType !== "commercialPlot" &&
                                selectedOptionType !== "agriculturalLand" &&
                                selectedOptionType !== "warehouse" &&
                                selectedOptionType !== "office" && (
                                  <div className="col-md-3 ">
                                    <select
                                      class="form-select form-select-md py-2"
                                      aria-label=".form-select-md example"
                                      id="beds"
                                      name="beds"
                                      style={{ fontSize: "12px" }}
                                      defaultValue={localStorage.getItem(
                                        "searchbeds"
                                      )}
                                    >
                                      <option value="" disabled selected>
                                        Beds
                                      </option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                      <option value="6">6</option>
                                      <option value="7">7</option>
                                      <option value="8">8</option>
                                      <option value="9">9</option>
                                      <option value="10">10</option>
                                      <option value="10+">10+</option>
                                    </select>
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default HomeSlider;
