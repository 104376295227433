import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Importing images
import AkbarVista02 from "./projectimages/Akbarvista.png";
import karat1 from "./projectimages/24karat.png";
import PBC from "./projectimages/PBC.png";
import Ayubia from "./projectimages/9kAyubia.png";
import BWTC from "./projectimages/BWTC.png";
import PN from "./projectimages/PeakNest.png";
import Arclink from "./projectimages/Arclink.png";
import Avenue from "./projectimages/Avenue18.png";
import Nathia from "./projectimages/9kNathiagali.png";

const ProjectList = () => {
  const [projects] = useState([
    {
      id: 2,
      title: "Avenue 18",
      location: "DHA Phase 1, Islamabad",
      type: "Commercial",
      city: "Islamabad",
      image: Avenue,
      link: "/avenue-18",
    },
    {
      id: 3,
      title: "Peak Nest Murree",
      location: "Mussiari Murree",
      type: "Residential",
      city: "Murree",
      image: PN,
      link: "/peak-nest",
    },
    {
      id: 4,
      title: "Pearl Business Center",
      location: "DHA Phase 1, Islamabad",
      type: "Commercial",
      city: "Islamabad",
      image: PBC,
      link: "/pearl-business-center",
    },
    {
      id: 5,
      title: "Akbar Vista",
      location: "Sector F, Plot No. 2-C, Bahria Enclave",
      type: "Residential and Commercial",
      city: "Islamabad",
      image: AkbarVista02,
      link: "/akbar-vista",
    },
    {
      id: 6,
      title: "27 Arc Link",
      location: "DHA Phase 1, Islamabad",
      type: "Commercial",
      city: "Islamabad",
      image: Arclink,
      link: "/27-arclink",
    },
    {
      id: 7,
      title: "24 Karat Crown",
      location: "Phase 8 Bahria Town Islamabad",
      type: "Residential and Commercial",
      city: "Islamabad",
      image: karat1,
      link: "/24-karat-crown",
    },
    {
      id: 8,
      title: "9K Suites Ayubia",
      location: "Ayubia, Galiyat, Murree",
      type: "Residential",
      city: "Murree",
      image: Ayubia,
      link: "/9k-suite-ayubia",
    },
    {
      id: 9,
      title: "9K Suites Nathia Gali",
      location: "Nathia Gali",
      type: "Residential",
      city: "Murree",
      image: Nathia,
      link: "/9k-suite-nathiagali",
    },
    {
      id: 1,
      title: "BWTC",
      location: "GT Road, Bahria Paradise Commercial, Rawalpindi",
      type: "Commercial",
      city: "Rawalpindi",
      image: BWTC,
      link: "/blue-world-trade-center",
    },
  ]);
  const [filteredProjects, setFilteredProjects] = useState(projects);
  const [search, setSearch] = useState({ projectName: "", city: "" });

  // Handle search
  const handleSearch = () => {
    const { projectName, city } = search;
    const filtered = projects.filter((project) => {
      const matchesName = projectName
        ? project.title.toLowerCase().startsWith(projectName.toLowerCase())
        : true;
      const matchesCity = city
        ? project.city.toLowerCase() === city.toLowerCase()
        : true;
      return matchesName && matchesCity;
    });
    setFilteredProjects(filtered);
  };

  useEffect(() => {
    handleSearch();
  }, [search]);

  return (
    <>
      {/* Search Section */}
      <div
        id="intro-example"
        className="text-center bg-image"
        style={{
          backgroundImage: `url(${require("./../../img/banner/bg_rectangle.png")})`,
        }}
      >
        <div className="container">
          <h3
            className="text-white my-2 pt-4"
            style={{ fontWeight: "700", fontSize: "36px" }}
          >
            Find New Projects
          </h3>
          <h5
            className="text-white pb-4"
            style={{ fontWeight: "400", fontSize: "36px" }}
          >
            Find exciting new real estate projects and investment opportunities
          </h5>
          <div className="card p-2">
            <div className="row p-2">
              <div className="col-md-5">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Project Name"
                  value={search.projectName}
                  onChange={(e) =>
                    setSearch({ ...search, projectName: e.target.value })
                  }
                  list={search.projectName.trim() ? "projectList" : undefined}
                />
                {search.projectName.trim() && (
                  <datalist id="projectList">
                    {projects
                      .filter((project) =>
                        project.title
                          .toLowerCase()
                          .startsWith(search.projectName.toLowerCase())
                      )
                      .map((project) => (
                        <option key={project.id} value={project.title} />
                      ))}
                  </datalist>
                )}
              </div>
              <div className="col-md-5">
                <select
                  className="form-select"
                  value={search.city}
                  onChange={(e) =>
                    setSearch({ ...search, city: e.target.value })
                  }
                >
                  <option value="">Select The City</option>
                  <option value="Islamabad">Islamabad</option>
                  <option value="Rawalpindi">Rawalpindi</option>
                  <option value="Murree">Murree</option>
                </select>
              </div>
              <div className="col-md-2">
                <button
                  className="btn btn-light btn-lg w-100"
                  style={{ backgroundColor: "#4DB749", color: "white" }}
                  onClick={handleSearch}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Project Cards */}
      <div className="container mt-4">
        <div
          className="row g-4"
          style={{ marginLeft: "20px", marginRight: "20px" }}
        >
          {filteredProjects.map((project) => (
            <div className="col-lg-4 col-md-6 col-sm-12" key={project.id}>
              <Link to={project.link}>
                <div
                  className="card text-white"
                  style={{
                    backgroundImage: `url(${project.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "300px",
                    margin: "10px",
                  }}
                >
                  <div className="card-body d-flex flex-column justify-content-between">
                    <h5 className="card-title" style={{ fontSize: "22px" }}>
                      {project.title}
                    </h5>
                    <div>
                      <p className="mb-1" style={{ color: "white" }}>
                        <i
                          className="bi bi-geo-alt"
                          style={{ color: "#4BB749" }}
                        ></i>{" "}
                        {project.location}
                      </p>
                      <p style={{ color: "white" }}>Type: {project.type}</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ProjectList;
