import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";
import { Link } from "react-router-dom";
import { IoIosBed } from "react-icons/io";
import { MdEmail } from "react-icons/md";

import { MdLocationOn } from "react-icons/md";
import { FaChartArea, FaBath } from "react-icons/fa";
import { LuPhoneCall } from "react-icons/lu";
import TopNav from "../TopNav";
import BottomNav from "../BottomNav";
import Footer from "../footer/Footer";
import axios from "axios";
import api_url from "../../ApiUrl";
import "../property/Allproperty.css";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const priceOptions = [
  0,
  5000,
  10000,
  20000,
  30000,
  40000,
  50000,
  75000,
  100000,
  150000,
  200000,
  300000,
  400000,
  500000,
  750000,
  1000000,
  1500000,
  2000000,
  3000000,
  4000000,
  5000000,
  10000000,
  20000000,
  30000000,
  40000000,
  50000000,
  null,
];

const areaOptions = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  12,
  15,
  20,
  50,
  100,
  200,
  500,
  "Any",
];

const unitOptions = [
  { label: "Marla", value: "marla" },
  { label: "Kanal", value: "kanal" },
];
const AllProperties = () => {
  const [myPost, setMyPost] = useState([]);

  const [minPrice, setMinPrice] = useState(
    localStorage.getItem("searchminprice") || ""
  );
  const [maxPrice, setMaxPrice] = useState(
    localStorage.getItem("searchmaxprice") || ""
  );

  const [minArea, setMinArea] = useState(
    localStorage.getItem("searchminarea") || ""
  );
  const [maxArea, setMaxArea] = useState(
    localStorage.getItem("searchmaxarea") || ""
  );
  const [areaUnit, setAreaUnit] = useState(
    localStorage.getItem("searchareaunit") || "Marla"
  );

  const [suggestions, setSuggestions] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("searchminarea", minArea);
  }, [minArea]);

  useEffect(() => {
    localStorage.setItem("searchmaxarea", maxArea);
  }, [maxArea]);

  useEffect(() => {
    localStorage.setItem("searchminprice", minPrice);
  }, [minPrice]);

  useEffect(() => {
    localStorage.setItem("searchmaxprice", maxPrice);
  }, [maxPrice]);

  const [selectedOption, setSelectedOption] = useState("For Sale");

  useEffect(() => {
    // Set initial value from localStorage
    const storedOption = localStorage.getItem("mysetprop") || "";
    setSelectedOption(storedOption);
  }, []);

  const handleClick = (option) => {
    localStorage.setItem("mysetprop", option);
    setSelectedOption(option);
  };

  // Fetch search suggestions
  const fetchSuggestions = (input) => {
    if (!input) {
      setSuggestions([]); // Clear suggestions if input is empty
      return;
    }

    axios
      .post(`${api_url}property/filter`, { location: input })
      .then((res) => {
        // console.log("location data for suggestions:", res.data);
        // Assuming each item in res.data.data has a 'title' or 'city' field that you want to display
        setSuggestions(res.data.data.map((item) => item.location || item.city)); // Only use a property like 'title' or 'city'
      })
      .catch((error) => {
        console.error("Error fetching suggestions: ", error);
      });
  };

  // Handle input change and fetch suggestions
  const handleSearchInputChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);
    fetchSuggestions(input);
  };

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    setSearchInput(suggestion);
    setSuggestions([]);
  };

  const submitFilter = () => {
    const city = document.getElementById("city");
    const type = document.getElementById("type");
    // const title = document.getElementById("title");
    const location = document.getElementById("location");
    const beds = document.getElementById("beds");
    const conversionFactors = {
      Marla: 25.2929,
      Kanal: 505.857,
      // Add more units if needed
    };

    // Convert area values to square meters
    const minAreaInSqMeters =
      minArea && areaUnit && !isNaN(parseFloat(minArea))
        ? parseFloat(minArea) * (conversionFactors[areaUnit] || 1)
        : null;

    const maxAreaInSqMeters =
      maxArea && areaUnit && !isNaN(parseFloat(maxArea))
        ? parseFloat(maxArea) * (conversionFactors[areaUnit] || 1)
        : null;

    const param = {
      city: city?.value || null,
      type: type?.value || null,
      location: location?.value || null,
      // title: title.value.trim() || null, // trim to remove extra spaces
      beds: beds?.value || null, // ensure integer value or null
      minPrice: minPrice || null, // Use state variable directly as a string
      maxPrice: maxPrice || null, // Use state variable directly as a string
      minArea: minAreaInSqMeters,
      maxArea: maxAreaInSqMeters,
      purpose: localStorage.getItem("mysetprop"), // add buy/rent type
    };

    // console.log(param, "Search Fields data");

    axios
      .post(`${api_url}property/filter`, param)
      .then((res) => {
        // console.log("API response data:", res.data); // Check what you received
        setMyPost(res.data.data); // Assuming the data is in res.data.data
      })
      .catch((error) => {
        console.error("Error fetching filtered properties: ", error);
      });
  };

  useEffect(() => {
    // Retrieve the query string from the URL
    const queryParams = new URLSearchParams(window.location.search);

    // Convert query parameters to an object
    const searchparam = {};
    queryParams.forEach((value, key) => {
      searchparam[key] = value;
    });

    // console.log("Retrieved URL parameters:", searchparam);

    // Use the extracted parameters for API filtering
    axios
      .post(`${api_url}property/filter`, searchparam)
      .then((res) => {
        // console.log("Filtered properties:", res.data);
        setMyPost(res.data.data);
        // console.log("Updated MyPost:", res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching properties:", error);
      });
  }, []);

  const [pricePop, setPricePop] = useState(false);
  const [bedPop, setBedPop] = useState(false);

  const formatTitle = (title) => {
    return title
      .toLowerCase() // Optional: convert to lowercase
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/[^\w\-]+/g, ""); // Remove special characters
  };

  // Convert area from square meters to marla and kanal
  const convertArea = (areaInSquareMeters) => {
    const marla = areaInSquareMeters / 25.2929;
    const kanal = Math.floor(marla / 20);
    const remainingMarla = Math.round(marla % 20);

    if (kanal > 0) {
      return `${kanal} Kanal${
        remainingMarla > 0 ? ` ${remainingMarla} Marla` : ""
      }`;
    } else {
      return `${remainingMarla} Marla`;
    }
  };

  // Convert price to thousand, lakh, and crore
  const formatPrice = (price) => {
    if (price >= 1_00_00_000) {
      return `${Math.floor(price / 1_00_00_000)} Crore`;
    } else if (price >= 1_00_000) {
      return `${Math.floor(price / 1_00_000)} Lakh`;
    } else if (price >= 1_000) {
      return `${Math.floor(price / 1_000)} Thousand`;
    } else {
      return `${price}`;
    }
  };

  const [selectedOptionType, setSelectedOptionType] = useState(""); // Default option

  return (
    <>
      <TopNav />
      <BottomNav />
      <Swiper
        spaceBetween={60}
        navigation
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper1"
      >
        <SwiperSlide>
          <div
            className="pb-5"
            style={{
              backgroundImage: `linear-gradient(rgba(139, 139, 136,0.3),rgba(139, 139, 136,0.3)),url(${require("../../assets/images/Main.jpg")})`,
              width: "100%",

              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <div className="container  justify-content-center align-items-center d-flex">
              <div className="row w-100">
                <div className="col-md-12">
                  <div className="text-center text-white pt-4">
                    <h2
                      style={{
                        fontSize: "2em",
                        fontWeight: "400",
                        borderRadius: 0,
                      }}
                    >
                      {selectedOption === "For Sale"
                        ? "Find Your Perfect Space To Buy"
                        : "Find Your Perfect Space To Rent"}
                    </h2>
                    <div
                      className="d-flex pt-3 justify-content-center"
                      style={{ gap: "2px" }}
                    >
                      <div className="slider_num">
                        <button
                          className="btn text-white px-5 py-2"
                          id="mybuy"
                          style={{
                            borderRadius: 0,
                            backgroundColor:
                              selectedOption === "For Sale"
                                ? "#3d3d3d"
                                : "#4db749",
                          }}
                          onClick={() => handleClick("For Sale")}
                        >
                          BUY
                        </button>
                      </div>
                      <div className="slider_num">
                        <button
                          className="btn text-white px-5 py-2"
                          id="myrent"
                          style={{
                            backgroundColor:
                              selectedOption === "For Rent"
                                ? "#3d3d3d"
                                : "#4db749",
                            borderRadius: 0,
                          }}
                          onClick={() => handleClick("For Rent")}
                        >
                          RENT
                        </button>
                      </div>
                    </div>
                    <div>
                      <div
                        className="py-2 slider_home2  mt-3"
                        style={{ backgroundColor: "#8B8B88" }}
                      >
                        <div className="row px-2">
                          <div className="col-md-3">
                            <select
                              className="form-select form-select-md py-2 "
                              name="city"
                              id="city"
                              required
                              defaultValue={localStorage.getItem("searchcity")}
                              style={{ fontSize: "12px" }}
                            >
                              <option value="">Select City</option>
                              <option value="Islamabad">Islamabad</option>

                              <option value="Rawalpindi">Rawalpindi</option>
                            </select>
                          </div>
                          <div className="col-md-6">
                            <input
                              style={{
                                fontSize: "16px",
                                borderRadius: 0,
                                lineHeight: 2,
                              }}
                              className="form-control py-2"
                              type="search"
                              placeholder="Location"
                              id="location"
                              value={searchInput}
                              onChange={handleSearchInputChange} // Fetch suggestions on input change
                            />
                            {suggestions.length > 0 && (
                              <ul className="suggestion-list">
                                {suggestions.map((suggestion, index) => (
                                  <li
                                    key={index}
                                    onClick={() =>
                                      handleSuggestionClick(suggestion)
                                    }
                                    className="suggestion-item"
                                  >
                                    {suggestion}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>

                          <div className="col-md-3">
                            <button
                              className="btn w-100 text-white"
                              style={{
                                backgroundColor: "#4DB749",
                                opacity: "0.8",

                                borderRadius: 0,
                              }}
                              onClick={submitFilter}
                            >
                              Find
                            </button>
                          </div>
                        </div>
                        <div className="row px-2 ">
                          <p
                            className="text-white"
                            onClick={toggleExpansion}
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              marginTop: "5px",
                              marginBottom: "0px",
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {isExpanded ? <FiChevronUp /> : <FiChevronDown />}{" "}
                            {isExpanded ? "Less Options" : "More Options"}
                          </p>
                        </div>
                        {/* Div to Show/Hide */}
                        {isExpanded && (
                          <div className=" row px-2 pt-1">
                            <div className="col-md-3">
                              <div>
                                <select
                                  class="form-select form-select-md "
                                  aria-label=".form-select-md example"
                                  placeholder="Property Type"
                                  style={{ fontSize: "12px" }}
                                  id="type"
                                  name="type"
                                  defaultValue={localStorage.getItem(
                                    "searchtype"
                                  )}
                                  value={selectedOptionType}
                                  onChange={(e) =>
                                    setSelectedOptionType(e.target.value)
                                  }
                                >
                                  <option value="" disabled>
                                    Property Type
                                  </option>
                                  <option value="house">House</option>
                                  <option value="farmHouse">Farm House</option>
                                  <option value="pentHouse">Pent House</option>
                                  <option value="upperPortion">
                                    Upper Portion
                                  </option>
                                  <option value="lowerPortion">
                                    Lower Portion
                                  </option>
                                  <option value="flat">Flat</option>
                                  <option value="office">Office</option>
                                  <option value="shop">Shop</option>

                                  <option value="plot">Plot</option>

                                  <option value="residentialPlot">
                                    Residential Plot
                                  </option>
                                  <option value="commercialPlot">
                                    Commercial Plot
                                  </option>
                                  <option value="agriculturalLand">
                                    Agricultural Land
                                  </option>
                                  <option value="industrialLand">
                                    Industrial Land
                                  </option>

                                  <option value="warehouse">WareHouse</option>
                                  <option value="factory">Factory</option>
                                  <option value="building">Building</option>
                                </select>
                              </div>
                            </div>

                            <div
                              className={`${
                                selectedOptionType === "plot" ||
                                selectedOptionType === "shop" ||
                                selectedOptionType === "residentialPlot" ||
                                selectedOptionType === "commercialPlot" ||
                                selectedOptionType === "office" ||
                                selectedOptionType === "agriculturalLand"
                                  ? "col-md-4"
                                  : "col-md-3"
                              } position-relative`}
                            >
                              <div
                                className="bg-white "
                                onClick={() => setPricePop(!pricePop)}
                              >
                                <input
                                  className="form-control p-0 bg-white border-0 px-3 "
                                  type="text"
                                  placeholder="Search Price"
                                  disabled
                                  style={{ fontSize: "12px", lineHeight: 2 }}
                                />
                                <div className="d-flex justify-content-between align-items-center m-0 p-0 px-3 ">
                                  <p className="p-0 m-0 pb-1 text-dark">
                                    {minPrice}
                                  </p>
                                  <p className="p-0 m-0 pb-1 text-dark">to</p>
                                  <p className="p-0 m-0 pb-1 text-dark">
                                    {maxPrice}
                                  </p>
                                  <p className="p-0 m-0 pb-1 text-dark">▼</p>
                                </div>
                              </div>

                              {pricePop ? (
                                <div className=" bg-white p-2 d-flex flex-column my-2 price-popup">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                      <h6 className="text-dark">MIN</h6>
                                      <select
                                        className="form-control"
                                        name="minPrice"
                                        value={minPrice}
                                        onChange={(e) => {
                                          setMinPrice(e.target.value);
                                          // Hide the popup when a value is selected
                                        }}
                                      >
                                        {priceOptions.map((price, index) => (
                                          <option key={index} value={price}>
                                            {price}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    <div>
                                      <h6 className="text-dark">MAX</h6>
                                      <select
                                        className="form-control"
                                        name="maxPrice"
                                        style={{ fontSize: "14px" }}
                                        value={maxPrice}
                                        onChange={(e) => {
                                          setMaxPrice(e.target.value);
                                          setPricePop(false); // Hide the popup when a value is selected
                                        }}
                                      >
                                        {priceOptions.map((price, index) => (
                                          <option key={index} value={price}>
                                            {price}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                            <div
                              className={`${
                                selectedOptionType === "plot" ||
                                selectedOptionType === "shop" ||
                                selectedOptionType === "residentialPlot" ||
                                selectedOptionType === "commercialPlot" ||
                                selectedOptionType === "office" ||
                                selectedOptionType === "agriculturalLand"
                                  ? "col-md-5"
                                  : "col-md-3"
                              } position-relative`}
                            >
                              <div
                                className="bg-white "
                                onClick={() => setBedPop(!bedPop)}
                              >
                                <input
                                  className="form-control p-0 bg-white border-0 px-3 "
                                  type="text"
                                  style={{ fontSize: "12px", lineHeight: 2 }}
                                  placeholder="Search Area"
                                  disabled
                                />
                                <div className="d-flex justify-content-between align-items-center m-0 p-0 px-3">
                                  <p className="p-0 m-0 pb-1 text-dark">
                                    {minArea}
                                  </p>
                                  <p className="p-0 m-0 pb-1 text-dark">to</p>
                                  <p className="p-0 m-0 pb-1 text-dark">
                                    {maxArea}
                                  </p>
                                  <p className="p-0 m-0 pb-1 text-dark">▼</p>
                                </div>
                              </div>

                              {bedPop ? (
                                <div className="bg-white p-2 d-flex flex-column my-2 price-popup">
                                  <div className="select-container">
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "10px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div>
                                        <h6 className="text-dark">MIN</h6>
                                        <input
                                          type="number"
                                          style={{ fontSize: "16px" }}
                                          className="form-control rounded-select"
                                          name="minArea"
                                          value={minArea}
                                          onChange={(e) => {
                                            setMinArea(e.target.value);
                                          }}
                                          placeholder="Enter minimum area"
                                        />
                                      </div>
                                      <div>
                                        <h6 className="text-dark">MAX</h6>
                                        <input
                                          type="number"
                                          className="form-control rounded-select"
                                          name="maxArea"
                                          style={{ fontSize: "16px" }}
                                          value={maxArea}
                                          onChange={(e) => {
                                            setMaxArea(e.target.value);

                                            setBedPop(false);
                                          }}
                                          placeholder="Enter maximum area"
                                        />
                                      </div>
                                    </div>

                                    <div>
                                      <h6 className="text-dark">UNIT</h6>
                                      <select
                                        className="form-control rounded-select"
                                        name="areaUnit"
                                        style={{ fontSize: "14px" }}
                                        value={areaUnit}
                                        onChange={(e) => {
                                          setAreaUnit(e.target.value);

                                          setBedPop(false);
                                        }}
                                      >
                                        {unitOptions.map((unit, index) => (
                                          <option
                                            key={index}
                                            value={unit.value}
                                          >
                                            {unit.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                            {/* Beds Field - Hidden if "Plot" is selected */}
                            {selectedOptionType !== "plot" &&
                              selectedOptionType !== "shop" &&
                              selectedOptionType !== "residentialPlot" &&
                              selectedOptionType !== "commercialPlot" &&
                              selectedOptionType !== "agriculturalLand" &&
                              selectedOptionType !== "warehouse" &&
                              selectedOptionType !== "office" && (
                                <div className="col-md-3 ">
                                  <select
                                    class="form-select form-select-md py-2"
                                    aria-label=".form-select-md example"
                                    id="beds"
                                    name="beds"
                                    placeholder="Beds"
                                    style={{ fontSize: "12px" }}
                                    defaultValue={localStorage.getItem(
                                      "searchbeds"
                                    )}
                                  >
                                    <option value="" disabled selected>
                                      Beds
                                    </option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="10+">10+</option>
                                  </select>
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <section className=" py-5">
        <div className="container">
          <h3>Properties</h3>
          <p>Found {myPost.length} Properties</p>
          <div className="row  ">
            <div className="col-md-3">
              <div className="row g-5">
                <div className="col-md-12 hide-on-mobile">
                  <img
                    src={require("../../assets/images/bwc.jpeg")}
                    alt="ads"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div>
                <div>
                  <h6>
                    Sort By: <span>Default Sorting </span>
                  </h6>
                </div>
                <div></div>
              </div>
              <div className="row g-4">
                {myPost.length > 0 ? (
                  myPost.map((i) => {
                    const formattedTitle = formatTitle(i.title);
                    const formattedArea = convertArea(i.size); // Convert area
                    const formattedPrice = formatPrice(i.price); // Format price

                    return (
                      <div key={i.id} className="col-md-6">
                        <div className="position-relative">
                          <Link
                            to={`/property/${formattedTitle}/${i.id}`}
                            onClick={() => {
                              localStorage.removeItem("mysetprop");
                            }}
                            className="text-dark"
                          >
                            <div
                              className="features bg-white p-2"
                              style={{
                                overflow: "hidden",
                                borderTopLeftRadius: "15px",
                                borderTopRightRadius: "15px",
                                borderBottomLeftRadius: "8px",
                                borderBottomRightRadius: "8px",
                              }}
                            >
                              <div
                                className="features_img"
                                style={{
                                  overflow: "hidden",
                                  borderRadius: 12,
                                }}
                              >
                                <img
                                  src={i?.image[0]}
                                  height="230px"
                                  width="100%"
                                  alt=""
                                />
                              </div>
                              <div className="fire_logo">
                                <img
                                  src={require("../../assets/images/fire.png")}
                                  alt=""
                                  width="30px"
                                />
                              </div>

                              <div className="top px-2">
                                <div className="d-flex pt-4 justify-content-between">
                                  <div>
                                    <h5 className="m-0">
                                      {i?.title
                                        ?.split(/\s+/)
                                        .slice(0, 6)
                                        .join(" ")}
                                      ...
                                    </h5>
                                    <span
                                      style={{
                                        color: "#B9B9B9",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <MdLocationOn size={20} color="#4db749" />
                                      {i?.location
                                        ?.split(/\s+/)
                                        .slice(0, 6)
                                        .join(" ")}
                                      ...
                                    </span>
                                  </div>
                                  <div
                                    className="text-center"
                                    style={{
                                      position: "absolute",
                                      right: 15,
                                    }}
                                  >
                                    <img
                                      src={i.agencyLogo}
                                      width="60px"
                                      alt=""
                                    />
                                  </div>
                                </div>

                                <div className="pt-3" style={{ gap: "30px" }}>
                                  <div className="d-flex align-items-center pb-2">
                                    <FaChartArea color="#B9B9B9" size={20} />
                                    <h6
                                      className="ps-2 mb-0 text-bold"
                                      style={{ fontSize: "14px" }}
                                    >
                                      Area {formattedArea}{" "}
                                      {/* Use formatted area */}
                                    </h6>
                                  </div>

                                  {i?.beds && (
                                    <div className="d-flex align-items-center">
                                      <IoIosBed color="#B9B9B9" size={20} />
                                      <h6
                                        className="mb-0 ps-2 text-black text-bold"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {i.beds} Beds
                                      </h6>
                                    </div>
                                  )}
                                  {i?.baths && (
                                    <div className="d-flex align-items-center pt-2">
                                      <FaBath color="#B9B9B9" size={20} />
                                      <h6
                                        className="mb-0 ps-2 text-black text-bold"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {i.baths} Baths
                                      </h6>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="footer px-2 pt-3 d-flex align-items-center justify-content-between">
                                <h5 className="house_price">
                                  PKR {formattedPrice}{" "}
                                  {/* Use formatted price */}
                                </h5>
                                <div
                                  className="d-flex ps-5"
                                  style={{ gap: "15px" }}
                                >
                                  <LuPhoneCall color="#4DB749" size={27} />
                                  <MdEmail color="#4DB749" size={27} />
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h2>No Property Found</h2>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default AllProperties;
